import { useTheme } from '@mui/material/styles';
import { motion } from 'framer-motion';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import _ from '@lodash';
import { useState, useRef } from 'react';
import { showMessage } from 'app/store/fuse/messageSlice';
import { constants } from '../../../../../constants';
import RetroactiveUpdateModal from './RetroactiveUpdateModal';
import BillingHistory from './BillingHistory';
import SummaryModal from './SummaryModal';
import { connect } from 'react-redux';
import client from 'client';
import FuseLoading from '@fuse/core/FuseLoading';
import Dialog from '@mui/material/Dialog';
import JobAction from 'app/stores/loadz/redux/Jobs/actions'
import { hasPermission } from 'app/main/loadz/utils';
import CircularLoader from 'app/main/loadz/components/CircularLoader';

function JobsHelpersFooterBilling({
  id,
  jsonGetById,
  deletespecial,
  job_billing_history,
  user,
  data,
  setJob_id,
  setEditJobBillingOpen,
  job_id,
  getPendingJobs,
  commissionMatrix = [],
  commissionMatrixChanged = false
}) {
  const dispatch = useDispatch();
  const methods = useFormContext();
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [billingmessage, setBillingMessage] = useState('');
  const [changinginvoices, setChangingInvoices] = useState(0);
  const [billingchanges, setBillingChanges] = useState();
  const [jobinvoices, setJobInvoices] = useState([]);
  const [openconfirm, setOpenConfirm] = useState(false);
  const [SummaryOpen, setSummaryOpen] = useState(false);
  const [invoicesPrev, setInvoicesPrev] = useState(false);
  const [getData, setgetData] = useState(false);
  const { formState, watch, getValues, setError, reset, setValue } = methods;
  const history = useHistory();
  const [conditional,setConditional] = useState([])

  const clearForm = {
    unit_id: 0,
    materials: [],
    material_cost_rate: null,
    billperunit: null,
    material_invoice_rate: null,
    contractor_rate_per_qty: null,
    contractor_rate_per_qty_special: null,
    contractors: [],
    driver_hourly_rate: null,
  };

  const _showMessage = (msg, color) => {
    dispatch(
      showMessage({
        message: msg, //text or html
        autoHideDuration: 6000, //ms
        anchorOrigin: {
          vertical: 'top', //top bottom
          horizontal: 'right', //left center right
        },
        variant: color, //success error info warning null
      })
    );
  };

  const Summary = () => {
    setgetData(true);
    setSummaryOpen(true);
  };

  function handleUpdateJobClose() {
    setJob_id('');
    setEditJobBillingOpen(false);
  }

  async function handleUpdateJob(
    retroactive,
    og_billing,
    default_billing,
    special_billing,
    sendemailslater,
    conditional
  ) {
    try {
      const user_type = data ? data?.user_type : user?.user_type
      //console.log("JOB BILLING FOR THIS JOB: ",jsonGetById[0].job_billing)
      console.log('VALUESSS', JSON.parse(JSON.stringify(getValues())));
      //console.log(id)
      // return
      setOpenConfirm(false);
      setDisabled(true);
      let body = JSON.parse(JSON.stringify(getValues()));
      console.log('DATA', data);
      console.log('USER', user);
      body.retroactive = retroactive;
      body.og_billing = og_billing;
      body.default_billing = default_billing;
      body.special_billing = special_billing;
      body.user_id = data ? data.id : user?.company?.staff_id == '0' ? user?.user_id : user?.company?.staff_id;
      body.deletespecial = deletespecial;
      body.sendemails = !sendemailslater;
      body.conditional = conditional
      body.user_type = data ? data?.user_type : user?.user_type;
      //body = JSON.stringify(body);
      //open te summarry modal with no data
      retroactive == true ? setSummaryOpen(true) : null;

      if (commissionMatrixChanged && commissionMatrix?.length) {
        body['commissionMatrix'] = commissionMatrix
      }

      const response = await client.put(`/jobs/job-billing/update/${job_id ? job_id : id}`, body);
      const res = await response.data;
      console.log('RES', res);

      if (res.error) {
        setDisabled(false);
        setSummaryOpen(false);
        setOpen(false);
        _showMessage(res.error.msg, 'error');
        return;
      }

      if (retroactive) {
        if (jobinvoices.comp.length > 0 || jobinvoices.sub.length > 0 || jobinvoices.op.length > 0) {
          _showMessage(
            `Billing details for job # ${
              jsonGetById[0].job_no 
            } was successfully updated! Edited Company Invoices: ${jobinvoices.comp.map((elem) => {
              return `${elem}`;
            })}
            , Edited Subcontractor Invoices: ${jobinvoices.sub.map((elem) => {
              return `${elem}`;
            })}
            , Edited Owner Operator Invoices: ${jobinvoices.op.map((elem) => {
              return `${elem}`;
            })}`,
            'success'
          );
        } else {
          _showMessage(
            `Billing details for job # ${jsonGetById[0].job_no} were successfully updated!`, 
            'success'
          );
        }
        if(user_type != 'dispatcher')
        {
          getPendingJobs({compacctnum: data ? data?.compacctnum : user?.company?.compacctnum},(error) => {console.log('Error getting Pending Jobs When updating job billing: ',error)})
        }
        setgetData(true);
        reset(clearForm);
        setDisabled(false);
      } else {
        _showMessage(
          `Billing details for Job # ${jsonGetById[0].job_no} was successfully updated`, //TODO improved message and need to replace ID for JOB Number.
          'success'
        );
        reset(clearForm);
        setDisabled(false);
        if(user_type != 'dispatcher')
        {
          getPendingJobs({compacctnum: data ? data?.compacctnum : user?.company?.compacctnum},(error) => {console.log('Error getting Pending Jobs When updating job billing: ',error)})
        }
        data ? handleUpdateJobClose() : history.push('/jobs');
      }
    } catch (error) {
      if (error.response.status != 200) {
        setDisabled(false);
        setSummaryOpen(false);
        setOpen(false);
        setDisabled(false);
        _showMessage(
          error.response?.data?.error?.msg
            ? error.response?.data?.error?.msg
            : 'Unexpected error occurred. Please try again', //TODO improved message
          'error'
        );
        return;
      }
    }
  }

  async function ReviewChanges() {
    //console.log("JOB BILLING FOR THIS JOB: ",jsonGetById[0].job_billing)
    //console.log("SPECIAL FOR THIS JOB: ",jsonGetById[1])

    //console.log(id)
    if (jsonGetById[0].job_billing.billing_type != 'sub') {
      const new_values = JSON.parse(JSON.stringify(getValues()));
      console.log('NEW VALUES', new_values);
      const bill_per_unit = new_values.bill_invoice_rate;
      const cost_per_unit = new_values.billperunit;
      const {
        material_cost_rate,
        material_invoice_rate,
        driver_hourly_rate,
        taxable,
        material_broker,
        rate_owner,
        rate_sub,
      } = new_values;

      const obj_values = {
        material_cost_rate: Number(
          material_cost_rate ? material_cost_rate.replace(/[^0-9.-]+/g, '') : 0
        ),
        material_invoice_rate: Number(
          material_invoice_rate ? material_invoice_rate.replace(/[^0-9.-]+/g, '') : 0
        ),
        bill_per_unit: Number(bill_per_unit ? bill_per_unit.replace(/[^0-9.-]+/g, '') : 0),
        cost_per_unit: Number(cost_per_unit ? cost_per_unit.replace(/[^0-9.-]+/g, '') : 0),
        driver_hourly_rate: Number(
          driver_hourly_rate ? driver_hourly_rate.replace(/[^0-9.-]+/g, '') : 0
        ),
        taxable,
        material_broker,
      };

      const old_values = {
        material_cost_rate: jsonGetById[0].job_billing.material_cost_rate,
        material_invoice_rate: jsonGetById[0].job_billing.material_invoice_rate,
        bill_per_unit: jsonGetById[0].job_billing.bill_per_unit,
        cost_per_unit: jsonGetById[0].job_billing.cost_per_unit,
        driver_hourly_rate: jsonGetById[0].job_billing.driver_hourly_rate,
        taxable: jsonGetById[0].job_billing.taxable,
        material_broker: jsonGetById[0].job_billing.material_broker,
      };

      const new_default = {
        rate_owner: Number(rate_owner ? rate_owner.replace(/[^0-9.-]+/g, '') : 0),
        rate_sub: Number(rate_sub ? rate_sub.replace(/[^0-9.-]+/g, '') : 0),
      };
      const old_default = {
        rate_owner: jsonGetById[0].job_billing.rate_owner,
        rate_sub: jsonGetById[0].job_billing.rate_sub,
      };

      const old_special = jsonGetById[1].subcontractor;
      const new_special = new_values.subcontractor ? new_values.subcontractor : [];
      let new_cond = []

      // these flgs determine what will happens if user selects retroactive feature
      let og_billing = false; // job billing that affects only the og tickets
      let default_billing = false; // job billing that affects default sub and owp rates
      let special_billing = false; // job billing that affects special conditions

      //og_billing
      Object.keys(obj_values).map((key) => {
        if (old_values[key] != obj_values[key]) {
          if(key != 'material_invoice_rate' && key != 'bill_per_unit'){
            if(!new_cond.includes(1)){
              new_cond.push(1)
            }
          }
          if(key == 'material_invoice_rate' || key == 'bill_per_unit')
          {
            if(!new_cond.includes(0)){
              new_cond.push(0)
            }
          }

          og_billing = true;
        }
      });
      // if true open dialog
      //A change occured in the job billing XX invoices will be changed

      //default_billing
      Object.keys(new_default).map((key) => {
        if (old_default[key] != new_default[key]) {
          if(!new_cond.includes(1)){
            new_cond.push(1)
          }
          default_billing = true;
        }
      });
      // if true open dialog
      // A change occured in the default rates for OWPS and SUBS

      //if any special conditions are added or changed it will prompt user
      //special_billing
      if (!old_special && !new_special) {
        special_billing = false;
      } else if (!old_special && new_special) {
        if(!new_cond.includes(1)){
          new_cond.push(1)
        }
        special_billing = true;
      } else if (old_special && !new_special) {
        if(!new_cond.includes(1)){
          new_cond.push(1)
        }
        special_billing = true;
      } else if (old_special.length == new_special.length) {
        old_special.map((elem) => {
          const found = new_special.find((inst) => {
            if (
              inst.subcontractor_comp_id == elem.subcontractor_comp_id &&
              Number(inst.unit_rate ? inst.unit_rate : 0) ==
                Number(elem.unit_rate ? elem.unit_rate : 0)
            ) {
              return true;
            }
          });
          if (!found) {
            if(!new_cond.includes(1)){
              new_cond.push(1)
            }
            special_billing = true;
          }
        });
      } else if (old_special.length != new_special.length) {
        if(!new_cond.includes(1)){
          new_cond.push(1)
        }
        special_billing = true;
      }
      // if true open dialog
      // A change occured in the special conditions

      //do you want to retroactively update the tickets
      console.log(og_billing, default_billing, special_billing,new_cond);
      if (og_billing || default_billing || special_billing) {
        let res = [];

        setInvoicesPrev(true);
        const response = await client.post(
          `${constants.URLLOCAL}/invoices/invoices-by-tickets/${job_id ? job_id : id}`,
          { oldvalues: jsonGetById, newvalues: new_values,compacct: data ? data.compacctnum : user?.company?.compacctnum }
        );
        res = await response.data;
        res = res.result;
        setJobInvoices(res);
        console.log(res);
        setInvoicesPrev(false);
        /* const response = await fetch(`${constants.URLLOCAL}/invoices/invoices-by-jobid/${id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'token': `${token}`,
          },
        });
        res = await response.json()
        res = res.result
        setJobInvoices(res) */
        //console.log(res)

        console.log('opening popup with message');

        OpenRetroactivemodal({
          og_billing,
          default_billing,
          special_billing,
          invoices:{comp:res?.comp?.length,sub:res?.sub?.length,op:res?.op?.length},
          conditional:new_cond
         
        });
      } else {
        handleUpdateJob();
      }
    } else {
      handleUpdateJob();
    }
  }

  async function OpenRetroactivemodal({ og_billing, default_billing, special_billing, invoices,conditional }) {
    let message =
      'Do you want the changes to affect all existing tickets in this job or only future ones?.'; //TODO improved message

    //if(default_billing)(message = message+`Owner Operator / Subcontractor Rates were edited. If you want to apply changes to all existing Owner Operator / Subcontractor tickets in this job click "APPLY CHANGES TO ALL" this will edit any invoices they have created. This only affects your Owner Operator / Subcontractor tickets. Otherwise apply changes going forward click "APPLY CHANGES GOING FORWARD".\n`)

    //if(special_billing)(message = message+`Owner Operator / Subcontractor Special Rates were edited. If you want to apply changes to all existing Owner Operator / Subcontractor tickets in this job click "APPLY CHANGES TO ALL" this will edit any invoices they have created. This only affects your Owner Operator / Subcontractor tickets. Otherwise apply changes going forward click "APPLY CHANGES GOING FORWARD".\n`)

    //if(og_billing){message = message+` Doing so will edit ${invoices} invoices`}

    setBillingChanges({ og_billing, default_billing, special_billing });
    setConditional(conditional)
    setBillingMessage(message);
    setChangingInvoices(invoices);
    setOpen(true);
    //on NO then just do normal updatejob
    //on YES send a flag "retroactive: true" and which aspects have changed "og_billing,default_billing,special_billing" on the update job function
    //in backend if flag is true then on og billing only get tickets that are og,
    // on default billing only edit tickets of subs and ops that dont appear on special cond list
    //on special billing only edit ticekts of subs and ops that appear on the special list
  }

  const [waitingCreateBilling, setWaitingCreateBilling] = useState(false);

  async function handleSaveJob() {
    try {
      const user_type = data ? data?.user_type : user?.user_type
      setValue('user_type', data ? data?.user_type : user?.user_type);
      setValue('customer_hauling', hasPermission('job-billing.read') && hasPermission('job-billing.browse') ? getValues('customer_hauling') : false);

      console.log('VALUESSS', getValues());

      setDisabled(true);
      setWaitingCreateBilling(true)

      let payload = getValues()
      
      payload['user_id'] = data ? data.id : user?.company?.staff_id == '0' ? user?.user_id : user?.company?.staff_id;
      
      if (commissionMatrixChanged && commissionMatrix?.length) {
        payload['commissionMatrix'] = commissionMatrix
      }
      setTimeout(async () => {
        const response = await client.post(
          `${constants.URLLOCAL}/jobs/job-billing/new/${job_id ? job_id : id}`,
          payload
        );
        const res = await response.data;
  
        if (res?.error?.msg) {
          _showMessage(res.error.msg, 'error');
          setDisabled(false);
          return;
        }
  
        _showMessage(
          `Successfully created billing details for job #${jsonGetById[0].job_no}.!`, //TODO improved message
          'success'
        );
        reset(clearForm);
        setDisabled(false);
        if(user_type == 'dispatcher')
        {
          getPendingJobs({compacctnum: data ? data?.compacctnum : user?.company?.compacctnum},(error) => {console.log('Error getting Pending Jobs When creating job billing: ',error)})
        }
        data ? handleUpdateJobClose() : history.push('/jobs');
        setWaitingCreateBilling(false)

      }, 2000);

      
    } catch (error) {
      setWaitingCreateBilling(false)
      console.log(error);
      _showMessage(`Oh! please try again, something went wrong`, 'error');
      setDisabled(false);
    }
  }

  function handleCancelCreateJob() {
    data ? handleUpdateJobClose() : history.push('/jobs');
  }

  return (
    <div>

      <Dialog open={waitingCreateBilling}
      fullScreen
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }}>
        <div className='flex flex-row items-center align-center justify-center w-full h-full'>
          <CircularLoader/>
        </div>
        
      </Dialog>

      <Dialog open={invoicesPrev}>
        <FuseLoading />
      </Dialog>
      <RetroactiveUpdateModal
        open={open}
        setOpen={setOpen}
        confirmopen={openconfirm}
        setConfirmOpen={setOpenConfirm}
        billingmessage={billingmessage}
        billingchanges={billingchanges}
        conditional={conditional}
        changinginvoices={changinginvoices}
        handleUpdateJob={handleUpdateJob}
      />

      <SummaryModal
        setJob_id={setJob_id}
        setEditJobBillingOpen={setEditJobBillingOpen}
        data={data}
        open={SummaryOpen}
        setOpen={setSummaryOpen}
        getData={getData}
        id={id}
      />

      <motion.div
        className="flex sm:flex-row mb-32 right buttons w-100 justify-end"
        initial={{ opacity: 0, x: 20 }}
        animate={{ opacity: 1, x: 0, transition: { delay: 0.3 } }}
      >
        <div>
          <div className="bottombtns flex">
            <div className="new-job-btn">
              <button onClick={handleCancelCreateJob}>Cancel</button>
            </div>
            <div className="new-job-btn">
              <button
                onClick={
                  jsonGetById.length > 0
                    ? jsonGetById[0].job_billing
                      ? ReviewChanges
                      : handleSaveJob
                    : null
                }
              >
                {jsonGetById.length > 0
                  ? jsonGetById[0].job_billing
                    ? 'Update'
                    : 'Create'
                  : 'Create'}
              </button>
            </div>
          </div>
        </div>
      </motion.div>
      {jsonGetById.length > 0 && jsonGetById[0].job_billing ? (
        <div className="flex flex-col pt-64">
          <BillingHistory
            job_id={job_id}
            data={data}
            id={id}
          />
        </div>
      ) : null}
      <div ref={job_billing_history} />
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  getPendingJobs: (payload, onError) => dispatch(JobAction.getPendingJobs(payload, onError)),
})

const mapStateToProps = (state) => {
  return {
    user: state?.loadz_auth?.AuthReducer?.user,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(JobsHelpersFooterBilling);
