import { useEffect, useState, useRef } from 'react';
import Box from '@mui/material/Box';
import { Formik, Form, Field } from 'formik';
import { constants } from '../../../../constants';
// import styles from "./TicketStyle.module.css";
import { styled, lighten, darken } from '@mui/system';
import { getDataFromS3 } from 'app/main/CompanyList/companyFunctions';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import Autocomplete from '@mui/material/Autocomplete';
import { Typography, TextField, Switch, Dialog, Button } from '@mui/material';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useDispatch } from 'react-redux';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import client from 'client';
import { connect } from 'react-redux';
import FuseLoading from '@fuse/core/FuseLoading/FuseLoading';
const token = localStorage.token;
const comp_acct = localStorage.comp_acct;

import JobTicketAction from 'app/stores/loadz/redux/JobsReport/actions';

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === 'light'
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled('ul')({
  padding: 0,
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  // maxHeight: "770px",
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 4,
  p: 0,
  over: 'scroll',
};

const wait_style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  // maxHeight: "770px",
  bgcolor: 'black',
  boxShadow: 24,
  borderRadius: 4,
  p: 0,
  border: 80,
  borderStyle: 'dashed',
  borderColor: 'red',
  borderWidth: 20,
};

const boderanimate = {
  padding: '0 30px 10px',
  paddingBottom: '0',
  // marginTop: "20px",
};
const cursorp = {
  cursor: 'pointer',
};
const printpdf = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'end',
  position: 'absolute',
  right: '10px',
  top: '10px',
  alignItems: 'center',
  width: 'auto',
  padding: '10px',
  background: 'red',
  borderRadius: '10px',
  paddingTop: '7px',
};
const regex = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/;
const time_regex = RegExp('[0-9]');

const CreateTicketModal = ({
  handleclose,
  open,
  user,
  data,
  job_id,
  getAllTicketJobs,
  ticketStatus,
}) => {
  const formikRef = useRef();

  const dispatch = useDispatch();
  const routeParams = useParams();
  const { id } = routeParams;
  const [JsonGetById, setJsonGetById] = useState(0);
  const [drivers, setdrivers] = useState([]);
  const [trucks, setTrucks] = useState([]);
  const [logo, setLogo] = useState('');
  const [errors, setErrors] = useState({});
  const [checked, setChecked] = useState(false);

  const [isSub, setIsSub] = useState(false);
  const [subId, setSubId] = useState(null);
  const [subName, setSubName] = useState(null);
  const [compLocation, setCompLocation] = useState('');
  const [subs, setSubs] = useState([]);
  const [owner_op, setOwner_Op] = useState([]);

  const [loading, setLoading] = useState(false);

  const getJonInfo = async () => {
    if (!isSub) {
      const subjob = await client.get(`/jobs/ticket/${job_id ? job_id : id}`);
      console.log('subjob1::', subjob);
      setJsonGetById(subjob.data[0]);
    }

    if (isSub && subId) {
      let getsubjobid = await client.post(`/jobs/sub-from-og`, {
        sub_comp_acct: subId.value,
        comp_acct: data ? data.compacctnum : user?.company?.compacctnum,
        job_id: job_id ? job_id : id,
      });
      console.log('subjobid::', getsubjobid);
      if (getsubjobid.err_message) {
        return dispatch(
          showMessage({
            message: getsubjobid.err_message, //text or html
            autoHideDuration: 6000, //ms
            anchorOrigin: {
              vertical: 'top', //top bottom
              horizontal: 'right', //left center right
            },
            variant: 'error', //success error info warning null
          })
        );
      }

      const subjob = await client.get(`/jobs/ticket/${getsubjobid.data.job_id}`);
      setJsonGetById(subjob.data[0]);
    }
  };

  useEffect(async () => {
    if (open) {
      setLoading(true);
      await getdrivers();
      await getTurcks();
      await getJonInfo();
      if (isSub) {
        await getsubs();
        if (subId) {
          let key = subId.logo;
          console.log(subId);
          let resp = await getS3Location(key);
          console.log('key::', key);

          // setLogo(resp?.data?.data?.fileLocation);
          setLogo(key);
        }
      }

      if (!isSub && !subId) {
        let key = data ? data.logo : user?.company?.logo;
        if (key.includes('http')) {
          setLogo(key);
        } else {
          setLogo(`${constants.URLLOCAL}/aws/signed?image=${key}`);
        }
      }
      setLoading(false);
    }
  }, [open, isSub, subId]);

  const onClose = () => {
    setErrors({});
    setChecked(false);
    setIsSub(false);
    setSubId(null);
    handleclose();
  };

  const submitManualCreateTicket = async (values) => {
    console.log('DATE::', values.date);
    console.log('TIME::', moment(values.date+'T'+values.time).format());
    let payload = {
      truck_id: values.truck_id.value,
      job_id: JsonGetById.job_info_id,
      driver_id: values.driver_id.value,
      date:  values.date,
      time:  moment(values.date+'T'+values.time).format(),
      wait_time: values.wait_time,
      wait_duration: values.wait_duration,
      status: values.status,
      qty: values.qty,
      scale_no: values.scale_no,
      tk_duration: values.tk_duration,
      token: `${token}`,
      comp_acct: values.driver_id.comp_acct,
      user_id: user?.staff ? user?.staff?.staff_u_id : user?.user_id,
    };

    let apiRes = null;
    if (isSub) {
      payload.comp_acct = subId.value;
      payload.isSub = true;
      console.log('hi');
      apiRes = await client.post('/ticket/manual', payload);
    }
    if (!isSub) {
      const comp = data ? data?.compacctnum : user?.company?.compacctnum;
      if (values.driver_id.comp_acct != comp) {
        payload.isSub = true;
        payload.ownerop = true;
        payload.parent_comp_acct = data ? data?.compacctnum : user?.company?.compacctnum;
      }
      apiRes = await client.post('/ticket/manual', payload);
    }

    apiRes = await apiRes.data;
    if (apiRes.err_message) {
      return dispatch(
        showMessage({
          message: apiRes.err_message, //text or html
          autoHideDuration: 6000, //ms
          anchorOrigin: {
            vertical: 'top', //top bottom
            horizontal: 'right', //left center right
          },
          variant: 'error', //success error info warning null
        })
      );
    } else {
      dispatch(
        showMessage({
          message: `Ticket # ${apiRes.tk_no} has been created`, //text or html
          autoHideDuration: 6000, //ms
          anchorOrigin: {
            vertical: 'top', //top bottom
            horizontal: 'right', //left center right
          },
          variant: 'success', //success error info warning null
        })
      );
      handleclose();
      setErrors({});
      await getAllTicketJobs(
        {
          page: 1,
          data: {
            id: job_id ? job_id : id,
            estatus: ticketStatus,
            compacctnum: data ? data?.compacctnum : user.company.compacctnum,
          },
        },
        (e) => {
          console.log(e);
        }
      );
    }
  };

  const getS3Location = async (key) => {
    if (key != null && key != '') {
      let data = {
        key: key,
      };
      return await getDataFromS3(data);
    }
  };

  const getsubs = async (info) => {
    try {
      const result = await client.post('/props/get-subcon', {
        compacctnum: data ? data.compacctnum : user?.company?.compacctnum,
      });
      const comp_subs = await result.data.subcon.map((e) => {
        return { value: e.compacctnum, label: e.compname, logo: e.logo, id: e.user_id };
      });

      comp_subs.sort((a, b) => {
        if (b.compname < a.compname) {
          return 1;
        } else {
          return -1;
        }
      });

      setSubs(comp_subs);
    } catch (error) {
      console.log(error);
    }
  };

  const getdrivers = async (info) => {
    let compacctnum = data ? data.compacctnum : user.company.compacctnum;
    try {
      if (!isSub) {
        console.log('driver::1');
        const driver = await client.post('/props/get-drivers', { compacctnum });

        let comp_drivers = await driver.data.drivers.map((e) => {
          return {
            value: e.dr_user_id,
            label: e.user_fname + ' ' + e.user_lname,
            comp_name: data ? data.compname : user.company.compname,
            comp_acct: data ? data.compacctnum : user.company.compacctnum,
          };
        });

        //get all owner ops

        let ownerop = await client.post('/ownerop/get', { comp_acct: compacctnum });
        ownerop = await ownerop.data.ownerops;

        //get all drivers for each owner op and push into driver array
        await Promise.all(
          await ownerop.map(async (elem) => {
            const driver = await client.post('/props/get-drivers', {
              compacctnum: elem.compacctnum,
            });
            //console.log(driver);
            if (
              driver?.data?.drivers &&
              Array.isArray(driver?.data?.drivers) &&
              driver?.data?.drivers.length > 0
            ) {
              await driver?.data?.drivers.map((e) => {
                comp_drivers.push({
                  value: e.dr_user_id,
                  label: e.user_fname + ' ' + e.user_lname,
                  comp_name: elem.compname,
                  comp_acct: elem.compacctnum,
                });
              });
            }
          })
        );
        //console.log('driver::1', comp_drivers);
        setdrivers(comp_drivers);
      }
      if (isSub && subId) {
        console.log('driver::2');
        const driver = await client.post('/props/get-drivers', { compacctnum: subId.value });
        const comp_drivers = await driver.data.drivers.map((e) => {
          return {
            value: e.dr_user_id,
            label: e.user_fname + ' ' + e.user_lname,
            comp_name: subId.label,
            comp_acct: subId.value,
          };
        });
        console.log('driver::1', comp_drivers);
        setdrivers(comp_drivers);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getTurcks = async () => {
    const web = 1;
    if (!isSub && (id || job_id)) {
      try {
        const result = await client.post('/truck/all', {
          comp_acct: data ? data.id : user.user_id,web
        });

        const comp_trucks = await result.data.map((e) => {
          return {
            value: e.idx_trucks_p,
            label: 'No. ' + e.t_no_truck,
            comp_name: data ? data.compname : user?.company?.compname,
          };
        });

        let ownerop = await client.post('/ownerop/get', {
          comp_acct: data ? data.compacctnum : user.company.compacctnum,
        });
        ownerop = await ownerop.data.ownerops;

        await Promise.all(
          await ownerop.map(async (elem) => {
            const driver = await client.post('/truck/all', { comp_acct: elem.user_id,web });

            await driver.data.map((e) => {
              comp_trucks.push({
                value: e.idx_trucks_p,
                label: 'No. ' + e.t_no_truck,
                comp_name: elem.compname,
              });
            });
          })
        );
        setTrucks(comp_trucks);
      } catch (error) {
        console.log(error);
      }
    }

    if (isSub && subId) {
      try {
        const result = await client.post('/truck/all', {web:1, comp_acct: subId.id });
        const comp_trucks = await result.data.map((e) => {
          return { value: e.idx_trucks_p, label: 'No. ' + e.t_no_truck, comp_name: subId.label };
        });

        setTrucks(comp_trucks);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const ErrorValidation = (values) => {
    let err_obj = {};

    if (isSub && !subId) {
      err_obj.sub = 'Please select a Sub Company';
    }

    if (values.truck_id == null || values.truck_id == undefined) {
      err_obj.truck_id = 'Please select a Truck No.';
    }
    if (values.driver_id == null || values.driver_id == undefined) {
      err_obj.driver_id = 'Please select a driver';
    }
    if (values.wait_time) {
      if (!values.wait_durationhr) {
        err_obj.wait_duration = 'Please enter a wait duration';
      }
      if (!values.wait_durationmin) {
        err_obj.wait_duration = 'Please enter a wait duration';
      }
      if (
        values.wait_duration == null ||
        values.wait_duration == undefined ||
        values.wait_duration == ':'
      ) {
        err_obj.wait_duration = 'Please enter a wait duration';
      }
    }
    if (values.qty == null || values.qty == undefined) {
      err_obj.qty = 'Please enter a quantity';
    }
    if (!values.date) {
      err_obj.date = 'Please enter a date';
    }

    if (JsonGetById.job_billing.commissions == false) {
      if (!values.tk_durationhr) {
        err_obj.tk_duration = 'Please enter a duration for the ticket';
      }
      if (!values.tk_durationmin) {
        err_obj.tk_duration = 'Please enter a duration for the ticket';
      }
      if (
        values.tk_duration == null ||
        values.tk_duration == undefined ||
        values.tk_duration == ':'
      ) {
        err_obj.tk_duration = 'Please enter a duration for the ticket';
      } else {
        if (
          values.wait_duration != null ||
          values.wait_duration != undefined ||
          values.wait_duration != ':'
        ) {
          const test_wtd = moment(
            `${moment().format('YYYY-MM-DD')} ${values.wait_duration}`
          ).format();
          const test_tkd = moment(
            `${moment().format('YYYY-MM-DD')} ${values.tk_duration}`
          ).format();

          if (moment(test_wtd).isAfter(test_tkd) == true) {
            err_obj.wait_duration = 'The wait duration must be less than the ticket duration';
          }
        }
      }
    }

    if (JsonGetById.job_billing.commissions == true) {
      if (values.time == null || values.time == undefined) {
        err_obj.time = 'Please enter a time';
      }
    }

    if (JsonGetById.scale == true) {
      if (values.scale_no.length <= 0 || !values.scale_no) {
        err_obj.scale_no = 'Please enter a scale #';
      }
    }

    if (values?.driver_id?.comp_name != values?.truck_id?.comp_name) {
      err_obj.driver_id = 'Truck and Driver company does not match!';
      err_obj.truck_id = 'Truck and Driver company does not match!';
    }

    return err_obj;
  };

  return (
    <>
      <div>
        <Dialog
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
        >
          {loading ? (
            <FuseLoading></FuseLoading>
          ) : (
            <Box className={`overflow-y-scroll onprint relative p-0 m-0`}>
              <div
                className="form-header flex justify-between items-center"
                style={{ padding: '10px 30px' }}
              >
                <div className="form-title">
                  <h2 className="font-bold">Create Ticket</h2>
                </div>
                <div
                  className="text-white close-x-btn"
                  style={{ marginTop: '0' }}
                  onClick={onClose}
                  sx={printpdf}
                >
                  <div className="close-btn">
                    <img
                      width="25px"
                      height="25px"
                      src="/assets/images/icons/icons8-close-white.svg"
                    />
                  </div>
                </div>
              </div>
              <div style={{ padding: '10px 30px' }}>
                <strong>Company & Owner Operators</strong>
                <Switch
                  checked={isSub}
                  onChange={(e) => {
                    setIsSub(e.target.checked);
                    setSubId(null);
                    formikRef.current?.resetForm();
                    setChecked(false);
                  }}
                />
                <strong>Subcontractor Companies</strong>
                {isSub ? (
                  <Autocomplete
                    disableListWrap
                    id="subs-auto-complete"
                    value={subId}
                    options={subs}
                    onChange={(e, newValue) => {
                      setSubId(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        id="subs-field"
                        {...params}
                        placeholder="Sub Comp"
                        sx={{ padding: 0 }}
                      />
                    )}
                  />
                ) : null}
                <p style={{ color: 'red', fontSize: 10 }}>{errors.sub}</p>
              </div>
              <Formik
                innerRef={formikRef}
                initialValues={{
                  truck_id: null,
                  driver_id: null,
                  date: null,
                  time: null,
                  wait_time: false,
                  //wait_duration: '00:00',
                  wait_durationhr: '',
                  wait_durationmin: '',
                  status: '1',
                  qty: null,
                  scale_no: '',
                  tk_durationhr: '',
                  tk_durationmin: '',
                  //tk_duration: '00:00'
                }}
                onSubmit={(values) => {
                  values.wait_duration = `${values.wait_durationhr}:${values.wait_durationmin}`;
                  values.tk_duration = `${values.tk_durationhr}:${values.tk_durationmin}`;
                  const err = ErrorValidation(values);
                  if (Object.keys(err).length <= 0) {
                    submitManualCreateTicket(values);
                  } else {
                    setErrors(err);
                  }
                }}
              >
                {({ touched, values, setFieldValue }) => (
                  <Form>
                    <div>
                      <div style={boderanimate}>
                        <div className="box-pop-details">
                          <div
                            className={`first-row flex flex-wrap px-20 py-10 items-center pb-0`}
                            style={{
                              border: values.wait_time
                                ? '1px solid red'
                                : isSub
                                ? '1px solid black'
                                : '1px solid #e9c917',
                              boxShadow: '0 0 2px #e5c617',
                            }}
                          >
                            <div className="w-1/3 text-center pr-10">
                              <div className="imgbox flex-wrap h-full w-full flex justify-center items-center">
                                <img
                                  alt="Logo"
                                  src={logo}
                                  style={{ width: '100%' }}
                                />
                              </div>
                            </div>
                            <div className="w-2/3 text-left py-8 printsizefont">
                              <div className="ticket-date flex justify-between items-center mb-8">
                                <strong>Ticket Date: </strong>{' '}
                                <div className="right-d w-99">
                                  <div className="ticket-date">
                                    <p className="p-4">
                                      {values.date
                                        ? moment(values.date).format('MMM DD,YYYY')
                                        : null}{' '}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div className="truck flex justify-between items-center mb-8">
                                <strong>Truck #: </strong>

                                <div className="right-d w-99">
                                  <div className="truck">
                                    <p className="p-0">
                                      <Autocomplete
                                        disabled={isSub && !subId ? true : false}
                                        name="truck_id"
                                        disableListWrap
                                        id="truck-auto-complete"
                                        value={values.truck_id}
                                        options={trucks}
                                        groupBy={(option) => option.comp_name}
                                        getOptionLabel={(option) => option.label}
                                        onChange={(e, newValue) => {
                                          setFieldValue('truck_id', newValue);
                                          console.log(newValue);
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            id="truck-field"
                                            {...params}
                                            placeholder="Truck No."
                                            sx={{ padding: 0 }}
                                          />
                                        )}
                                        renderGroup={(params) => (
                                          <li>
                                            <GroupHeader>{params.group}</GroupHeader>
                                            <GroupItems>{params.children}</GroupItems>
                                          </li>
                                        )}
                                      />
                                    </p>
                                    <p style={{ color: 'red', fontSize: 10 }}>{errors.truck_id}</p>
                                  </div>
                                </div>
                              </div>

                              <div className="job flex justify-between items-center mb-8">
                                <strong>Job: </strong>{' '}
                                <div className="right-d w-99">
                                  <div className="job">
                                    {JsonGetById.company_id}-{JsonGetById.job_no}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className={`w-full text-center p-8`}
                            style={{ fontSize: '14px', fontWeight: 'bold' }}
                          >
                            <LocationOnIcon /> {JsonGetById.compaddress} , {JsonGetById.compcity} ,{' '}
                            {JsonGetById.compstate} , {JsonGetById.compzip}
                          </div>
                          <div
                            className={`second-row flex flex-wrap mb-10 printsizeheading`}
                            style={{
                              border: values.wait_time
                                ? '1px solid red'
                                : isSub
                                ? '1px solid black'
                                : '1px solid #e9c917',
                              boxShadow: 'rgb(0 0 1 / 11%) 0px 6px 10px',
                            }}
                          >
                            <div
                              className={`w-full text-center p-5 printsizeh`}
                              style={{
                                background: values.wait_time ? 'red' : isSub ? 'black' : '#e9c917',
                                fontSize: '20px',
                                fontWeight: 'bold',
                                color: values.wait_time ? null : isSub ? 'white' : null,
                              }}
                            >
                              Delivery Ticket
                            </div>
                            <div className="w-full flex flex-wrap p-16 pb-8 pt-8 printsizefont printsizedt">
                              <div
                                className="w-full flex flex-wrap items-center"
                                style={{ borderBottom: '1px solid black' }}
                              >
                                <div className="w-1/2 text-left p-0">
                                  <p className="py-8">
                                    <strong>Customer: </strong>{' '}
                                  </p>
                                </div>
                                <div className="w-1/2 text-left p-0">
                                  <p className="py-8">{JsonGetById.customer_name}</p>
                                </div>
                              </div>

                              <div
                                className="w-full flex flex-wrap items-center"
                                style={{ borderBottom: '1px solid black' }}
                              >
                                <div className="w-1/2 text-left p-0">
                                  <p className="py-8">
                                    <strong>Quarry Name: </strong>{' '}
                                  </p>
                                </div>
                                <div className="w-1/2 text-left p-0">
                                  <p className="py-8">
                                    <LocationOnIcon /> {JsonGetById?.quarry_pit?.l_name}
                                  </p>
                                </div>
                              </div>

                              <div
                                className="w-full flex flex-wrap items-center"
                                style={{ borderBottom: '1px solid black' }}
                              >
                                <div className="w-1/2 text-left p-0">
                                  <p className="py-8">
                                    <strong>Destination Name: </strong>{' '}
                                  </p>
                                </div>
                                <div className="w-1/2 text-left p-0">
                                  <p className="py-8">
                                    <LocationOnIcon /> {JsonGetById?.j_destination?.l_name}
                                  </p>
                                </div>
                              </div>

                              <div
                                className="w-full flex flex-wrap items-center"
                                style={{ borderBottom: '1px solid black' }}
                              >
                                <div className="w-1/2 text-left p-0">
                                  <p className="py-8">
                                    <strong>Driver: </strong>{' '}
                                  </p>
                                </div>
                                <div className="w-1/2 text-left p-0">
                                  <p className="py-8">
                                    <Autocomplete
                                      //onKeyDown={(e)=>{if(e.key = 'tab'){document.getElementById("truck-auto-complete").focus()}}}
                                      disabled={isSub && !subId ? true : false}
                                      name="driver_id"
                                      disableListWrap
                                      id="driver-auto-complete"
                                      value={values.driver_id}
                                      options={drivers}
                                      groupBy={(option) => option.comp_name}
                                      getOptionLabel={(option) => option.label}
                                      onChange={(e, newValue) => {
                                        setFieldValue('driver_id', newValue);
                                        console.log('NEW VALUE DRIVER > ', newValue);
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          autoFocus
                                          {...params}
                                          placeholder="Driver"
                                          sx={{ padding: 0 }}
                                        />
                                      )}
                                      renderGroup={(params) => (
                                        <li>
                                          <GroupHeader>{params.group}</GroupHeader>
                                          <GroupItems>{params.children}</GroupItems>
                                        </li>
                                      )}
                                    />

                                    {/* </Field> */}
                                  </p>
                                  <p style={{ color: 'red', fontSize: 10 }}>{errors.driver_id}</p>
                                </div>
                              </div>
                              {JsonGetById &&
                              JsonGetById.job_billing &&
                              JsonGetById.job_billing.commissions == true ? (
                                <div
                                  className="w-full flex flex-wrap items-center"
                                  style={{ borderBottom: '1px solid black' }}
                                >
                                  <div className="w-1/2 text-left p-0">
                                    <p className="py-8">
                                      <strong>Time: </strong>{' '}
                                    </p>
                                  </div>
                                  <div className="w-1/2 text-left p-0">
                                    <p className="py-8">
                                      <Field
                                        name="time"
                                        className="border text-sm text-black p-10 w-full"
                                        type="time"
                                      />
                                    </p>
                                    <p style={{ color: 'red', fontSize: 10 }}>{errors.time}</p>
                                  </div>
                                </div>
                              ) : null}

                              <div
                                className="w-full flex flex-wrap items-center"
                                style={{ borderBottom: '1px solid black' }}
                              >
                                <div className="w-1/2 text-left p-0">
                                  <p className="py-8">
                                    <strong>Date: </strong>{' '}
                                  </p>
                                </div>
                                <div className="w-1/2 text-left p-0">
                                  <p className="py-8">
                                    <Field
                                      name="date"
                                      className="w-full border text-sm text-black p-10"
                                      type="date"
                                    />
                                  </p>
                                  <p style={{ color: 'red', fontSize: 10 }}>{errors.date}</p>
                                </div>
                              </div>
                              <div
                                className="w-full flex flex-wrap items-center"
                                style={{ borderBottom: '1px solid black' }}
                              >
                                <div className="w-1/2 text-left p-0">
                                  <p className="py-8">
                                    <strong>Wait Time: </strong>{' '}
                                  </p>
                                </div>
                                <div className="w-1/2 text-center p-0">
                                  <p className="py-8">
                                    <Switch
                                      name="wait_time"
                                      checked={checked}
                                      onChange={(e) => {
                                        setChecked(e.target.checked);
                                        setFieldValue('wait_time', e.target.checked);
                                      }}
                                    />
                                  </p>
                                </div>
                              </div>
                              {values.wait_time ? (
                                <div
                                  className="w-full flex flex-wrap items-center"
                                  style={{ borderBottom: '1px solid black' }}
                                >
                                  <div className="w-1/2 text-left p-0">
                                    <p className="py-8">
                                      <strong>Wait Duration: </strong>{' '}
                                    </p>
                                  </div>
                                  <div className="w-1/2 text-left p-0">
                                    <div className="flex flex-row py-8">
                                      <TextField
                                        sx={{ width: '30%', paddingRight: 0 }}
                                        onChange={(e) => {
                                          setFieldValue('wait_durationhr', e.target.value);
                                        }}
                                        inputProps={{ maxLength: 2 }}
                                        onInput={(e) => {
                                          if (!time_regex.test(e.target.value)) {
                                            e.target.value = e.target.value.slice(0, -1);
                                          }
                                        }}
                                        size="small"
                                      />
                                      <Typography
                                        sx={{ paddingTop: 2, paddingRight: 1 }}
                                        fontSize={12}
                                      >
                                        hrs
                                      </Typography>
                                      <Typography
                                        className=""
                                        fontSize={24}
                                      >
                                        :
                                      </Typography>
                                      <TextField
                                        sx={{ width: '30%', paddingLeft: 1 }}
                                        onChange={(e) => {
                                          setFieldValue('wait_durationmin', e.target.value);
                                        }}
                                        inputProps={{ maxLength: 2 }}
                                        onInput={(e) => {
                                          if (!time_regex.test(e.target.value)) {
                                            e.target.value = e.target.value.slice(0, -1);
                                          }
                                        }}
                                        size="small"
                                      />
                                      <Typography
                                        sx={{ paddingTop: 2, paddingLeft: 0 }}
                                        fontSize={12}
                                      >
                                        min
                                      </Typography>
                                    </div>
                                    <p style={{ color: 'red', fontSize: 10 }}>
                                      {errors.wait_duration}
                                    </p>
                                  </div>
                                </div>
                              ) : null}
                              {JsonGetById &&
                              JsonGetById.job_billing &&
                              JsonGetById.job_billing.commissions == false ? (
                                <div
                                  className="w-full flex flex-wrap items-center"
                                  style={{ borderBottom: '1px solid black' }}
                                >
                                  <div className="w-1/2 text-left p-0">
                                    <p className="py-8">
                                      <strong> Ticket Duration: </strong>{' '}
                                    </p>
                                  </div>
                                  <div className="w-1/2 text-left p-0">
                                    <p className="py-8">
                                      <div className="flex flex-row py-8">
                                        <TextField
                                          sx={{ width: '30%', paddingRight: 0 }}
                                          onChange={(e) => {
                                            setFieldValue('tk_durationhr', e.target.value);
                                          }}
                                          inputProps={{ maxLength: 2 }}
                                          onInput={(e) => {
                                            if (!time_regex.test(e.target.value)) {
                                              e.target.value = e.target.value.slice(0, -1);
                                            }
                                          }}
                                          size="small"
                                        />
                                        <Typography
                                          sx={{ paddingTop: 2, paddingRight: 1 }}
                                          fontSize={12}
                                        >
                                          hrs
                                        </Typography>
                                        <Typography
                                          className=""
                                          fontSize={24}
                                        >
                                          :
                                        </Typography>
                                        <TextField
                                          sx={{ width: '30%', paddingLeft: 1 }}
                                          onChange={(e) => {
                                            setFieldValue('tk_durationmin', e.target.value);
                                          }}
                                          inputProps={{ maxLength: 2 }}
                                          onInput={(e) => {
                                            if (!time_regex.test(e.target.value)) {
                                              e.target.value = e.target.value.slice(0, -1);
                                            }
                                          }}
                                          size="small"
                                        />
                                        <Typography
                                          sx={{ paddingTop: 2, paddingLeft: 0 }}
                                          fontSize={12}
                                        >
                                          min
                                        </Typography>
                                      </div>{' '}
                                    </p>
                                    <p style={{ color: 'red', fontSize: 10 }}>
                                      {errors.tk_duration}
                                    </p>
                                  </div>
                                </div>
                              ) : null}

                              <div
                                className="w-full flex flex-wrap items-center"
                                style={{ borderBottom: '1px solid black' }}
                              >
                                <div className="w-1/2 text-left p-0">
                                  <p className="py-8">
                                    <strong>Status: </strong>{' '}
                                  </p>
                                </div>
                                <div className="w-1/2 text-left p-0">
                                  <p className="py-8">
                                    <Field
                                      component="select"
                                      name="status"
                                      placeholder="Status"
                                      className="w-full text-sm text-black p-10 border"
                                      style={{ appearance: 'auto' }}
                                    >
                                      <option
                                        disabled
                                        value=""
                                        selected
                                      >
                                        Select Status
                                      </option>
                                      <option value="0"> In Progress</option>
                                      <option value="1">Complete</option>
                                      <option value="2">Delay</option>
                                    </Field>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className={`second-row flex flex-wrap mb-10 printsizetab`}
                            style={{
                              border: values.wait_time
                                ? '1px solid red'
                                : isSub
                                ? '1px solid black'
                                : '1px solid #e9c917',
                              boxShadow: 'rgb(0 0 1 / 11%) 0px 6px 10px',
                            }}
                          >
                            <div className="w-full flex flex-wrap p-0 printsizetab2">
                              <table className="w-full table-fixed">
                                <tbody className="w-full">
                                  <tr
                                    className="w-full text-center py-10"
                                    style={{
                                      background: values.wait_time
                                        ? 'red'
                                        : isSub
                                        ? 'black'
                                        : '#e9c917',
                                      fontSize: '16px',
                                      color: values.wait_time ? null : isSub ? 'white' : null,
                                    }}
                                  >
                                    <th className={`py-10 w-full`}>
                                      QTY
                                      <p style={{ color: 'red', fontSize: 10 }}>{errors.qty}</p>
                                    </th>
                                    <th className={`py-10 w-full `}>UNITS</th>
                                    {JsonGetById.scale ? (
                                      <th className={`py-10 w-full`}>
                                        Scale No
                                        <p style={{ color: 'red', fontSize: 10 }}>
                                          {errors.scale_no}
                                        </p>
                                      </th>
                                    ) : null}

                                    <th className={`py-10 w-full `}>MATERIAL</th>
                                  </tr>

                                  <tr className="w-full text-center py-10">
                                    <td className="p-4 w-full">
                                      <span className="py-3 w-full">
                                        <Field
                                          name="qty"
                                          className="border p-10 w-full text-center"
                                          type="text"
                                          onInput={(e) => {
                                            if (!regex.test(e.target.value)) {
                                              e.target.value = e.target.value.slice(0, -1);
                                            }
                                          }}
                                        />
                                      </span>
                                    </td>
                                    <td className="p-4 w-full">
                                      <Field
                                        disabled
                                        name="unit"
                                        className="border p-10 w-full text-center"
                                        value={
                                          JsonGetById &&
                                          JsonGetById.job_billing &&
                                          JsonGetById.job_billing.unit_name
                                            ? JsonGetById.job_billing.unit_name
                                            : null
                                        }
                                      />
                                    </td>
                                    {JsonGetById.scale ? (
                                      <td className="p-4 w-full">
                                        <Field
                                          name="scale_no"
                                          className="border p-10 w-full text-center"
                                          type="text"
                                          onInput={(e) => {
                                            if (!regex.test(e.target.value)) {
                                              e.target.value = e.target.value.slice(0, -1);
                                            }
                                          }}
                                        />
                                      </td>
                                    ) : null}
                                    <td className="p-4 w-full">
                                      <Field
                                        disabled
                                        className="border p-10 w-full text-center"
                                        value={
                                          JsonGetById &&
                                          JsonGetById.job_billing &&
                                          JsonGetById.job_billing.name
                                            ? JsonGetById.job_billing.name
                                            : null
                                        }
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="flex flex-row items-center justify-center h-full  p-16"
                    >
                      <Button
                        className="cursor-pointer text-18"
                        variant='contained'
                        style={{
                          width:'30%',
                          height:'25%',
                          color: values.wait_time ? null : isSub ? 'white' : null,
                          background: values.wait_time ? 'red' : isSub ? 'black' : '#e9c917',
                        }}
                        type="submit"
                      >
                        Submit
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </Box>
          )}
        </Dialog>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.loadz_auth?.AuthReducer?.user,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getAllTicketJobs: (payload, onError) =>
    dispatch(JobTicketAction.getAllTicketJob(payload, onError)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateTicketModal);
